import { useState } from "react";
import { motion } from "framer-motion";

export function Example() {
  const [animate, setAnimate] = useState({ scale: 1, rotate: 0 });

  return (
    <motion.div
      style={{
        width: 150,
        height: 150,
        borderRadius: 30,
        backgroundColor: "#fff",
        cursor: "pointer"
      }}
      animate={animate}
      onTapStart={() => setAnimate({ scale: 1.25, rotate: 90 })}
      onTap={() => setAnimate({ scale: 1, rotate: 0 })}
    />
  );
}
